import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@swibeco/ui';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useSubmitOnChange } from '@swibeco/shared';
import * as Styles from './Contract.style';
import LunchCardContractPreviewModal from './ContractModal';
import { StepperContext } from '../Stepper/Stepper';

type ContractElementType = {
  termsConditions: string;
  termsConditionsValidity: string;
  contract: string;
  step: number;
  afterSubmitHandler: (data: boolean) => void;
};

const ContractElement = ({
  termsConditions,
  termsConditionsValidity,
  contract,
  step,
  afterSubmitHandler,
}: ContractElementType) => {
  const [isContractPreviewOpen, setContractPreviewOpen] = useState(false);
  const toggleContractPreview = () =>
    setContractPreviewOpen(!isContractPreviewOpen);
  const { setStepValid } = useContext(StepperContext);

  const [contractAccepted, setContractAccepted] = useState(false);

  const schema = yup.object().shape({
    contract: yup
      .boolean()
      .required(termsConditionsValidity)
      .oneOf([true], termsConditionsValidity),
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
    watch,
    register,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      contract: contractAccepted,
    },
  });

  const hasErrors = !!errors?.contract;

  // we use this effect to update our validation message when the user change language
  useEffect(() => {
    if (hasErrors) trigger('contract');
  }, [termsConditionsValidity, hasErrors, trigger]);

  const formTermsConditions = watch('contract');

  // console.log(formTermsConditions);

  const submitOnChange = useCallback(
    (data: { contract: boolean }) => {
      setContractAccepted(data.contract);
      afterSubmitHandler(data.contract);
    },
    [afterSubmitHandler]
  );

  useSubmitOnChange(
    handleSubmit(submitOnChange),
    isValid,
    useMemo(() => [formTermsConditions], [formTermsConditions]),
    isDirty
  );

  useEffect(() => {
    setStepValid(isValid, step);
  }, [isValid, setStepValid, step]);

  /**
   * @todo Guillaume
   * Inject the company's address into the contract after fetching
   * packages/flex-web/src/pages/OrderCardPage/forms/ContractForm.tsx
   */

  return (
    <Container fluid className="p-0">
      <Row>
        <Styles.ContractWrapper className="mt-3">
          <Row>
            <Styles.Contract className="position-relative">
              <Styles.ContractPreview
                data-testid="contract-preview"
                srcDoc={contract}
                sandbox="allow-same-origin"
              />
              <Styles.ContractPreviewModalButton
                onClick={toggleContractPreview}
                data-testid="contract-preview-button"
                className="d-flex align-items-center justify-content-center position-absolute"
              >
                <Styles.Icon icon={faSearchPlus} />
              </Styles.ContractPreviewModalButton>
            </Styles.Contract>
          </Row>
        </Styles.ContractWrapper>
      </Row>
      <Styles.TermsWrapper className="col-12 pt-5">
        <Checkbox
          label={termsConditions}
          {...register('contract')}
          defaultChecked={contractAccepted}
          errors={errors}
          data-testid="contract"
        />
      </Styles.TermsWrapper>
      <LunchCardContractPreviewModal
        isOpen={isContractPreviewOpen}
        toggle={toggleContractPreview}
      >
        <Styles.ContractPreview
          srcDoc={contract}
          sandbox="allow-same-origin"
          className="preview-in-modal"
        />
      </LunchCardContractPreviewModal>
    </Container>
  );
};

export default ContractElement;
