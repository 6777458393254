import React from 'react';
import { Row, ModalBody, ModalProps } from 'reactstrap';
import * as Styles from './ContractModal.style';

type LunchCardContractPreviewModalProps = ModalProps & {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
};

const LunchCardContractPreviewModal = ({
  isOpen,
  toggle,
  children,
  ...rest
}: LunchCardContractPreviewModalProps) => {
  const externalCloseButton = (
    <Row>
      <Styles.CloseButtonWrapper sm={12} className="position-relative">
        <Styles.CloseButton className="close" onClick={toggle}>
          &times;
        </Styles.CloseButton>
      </Styles.CloseButtonWrapper>
    </Row>
  );

  return (
    <Styles.Modal
      isOpen={isOpen}
      toggle={toggle}
      external={externalCloseButton}
      data-testid="lunch-card-contract-preview-modal"
      {...rest}
    >
      <ModalBody
        className="h-100"
        data-testid="lunch-card-contract-preview-modal-body"
      >
        {children}
      </ModalBody>
    </Styles.Modal>
  );
};

export default LunchCardContractPreviewModal;
