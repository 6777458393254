import { BaseSyntheticEvent, useRef, useEffect } from 'react';

const useSubmitOnChange = (
  onSubmit: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>,
  isFormValid: boolean,
  watchedValues: any[],
  isInputsDirty: boolean
) => {
  const previousWatchedValues = useRef(watchedValues);

  useEffect(() => {
    const difference = watchedValues.filter(
      (value, index) => previousWatchedValues.current[index] !== value
    );

    if (difference.length > 0 && isFormValid && isInputsDirty) {
      previousWatchedValues.current = watchedValues;
      onSubmit();
    }
  }, [isFormValid, onSubmit, watchedValues, isInputsDirty]);
};

export default useSubmitOnChange;
