import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ErrorMessage, Text } from '@swibeco/ui';
import { StepperContext } from './Stepper';

type StepButtonProps = {
  buttonContent: {
    previous: string;
    next: string;
    validate: string;
    validationError: string;
  };
  errors?: {
    errorState: boolean;
    details: string;
  };
  step: number;
};

const StepButton = ({ buttonContent, step, errors }: StepButtonProps) => {
  const { next, currentStep, numberOfSteps, isStepValid, goTo } =
    useContext(StepperContext);
  const { t } = useTranslation();

  return (
    <>
      {errors?.errorState && (
        <div className="d-flex col-12 justify-content-center mt-4">
          <Text>
            <ErrorMessage className="text-center">
              <div>{t(buttonContent.validationError)}</div>
              <div>{errors?.details && `Error code: ${errors?.details}`}</div>
            </ErrorMessage>
          </Text>
        </div>
      )}
      <div className="d-flex w-100 my-5 justify-content-center flex-column-reverse flex-lg-row">
        {step > 0 ? (
          <Button
            className="mr-lg-3 mt-3 mt-lg-0 flex-grow-1 flex-lg-grow-0"
            onClick={() => goTo(step)}
          >
            {t(buttonContent.previous)}
          </Button>
        ) : null}
        <Button
          disabled={!isStepValid}
          onClick={next}
          color="primary"
          className="flex-grow-1 flex-lg-grow-0"
          data-testid="btn-valid-form"
        >
          {t(
            currentStep === numberOfSteps
              ? buttonContent.validate
              : buttonContent.next
          )}
        </Button>
      </div>
    </>
  );
};

export default StepButton;
