import { OnboardingActions, userTypes, OnboardingUserState } from '../types';

const setUser = (userInfo: OnboardingUserState): OnboardingActions => ({
  type: userTypes.SET_USER,
  payload: userInfo,
});

const changeLanguage = (language: string): OnboardingActions => ({
  type: userTypes.CHANGE_LANGUAGE,
  payload: language,
});

const personalInfoStep = (userInfo: {
  gender: string;
  recoveryEmail: string;
  newsletter: boolean;
  hr_newsletter?: boolean;
  picture: string | undefined;
  termsUse: boolean;
  axaTermsAccepted: boolean;
}): OnboardingActions => ({
  type: userTypes.PERSONAL_INFO_STEP,
  payload: userInfo,
});

const passwordStep = (password: string): OnboardingActions => ({
  type: userTypes.PASSWORD_STEP,
  payload: password,
});

export const onboardingUserActions = {
  setUser,
  changeLanguage,
  personalInfoStep,
  passwordStep,
};
