import { getUserPerActivationCode, getUserPerId } from './getUserApi';
import { getCompanyPerToken, createUserPerCompanyToken } from './anonymousApi';
import { updateUser } from './patchUserApi';

export const onboardingAPI = {
  createUserPerCompanyToken,
  getCompanyPerToken,
  getUserPerActivationCode,
  getUserPerId,
  updateUser,
};
