export const user = {
  id: null,
  first_name: '',
  last_name: '',
  password: null,
  email: '',
  gender: null,
  language: 'en',
  picture: null,
  recovery_email: null,
  newsletter: false,
  hr_newsletter: false,
  terms_accepted: false,
  activation_code: null,
};

export const company = {
  id: null,
  name: '',
  onboarding_complete: false,
  dealer_id: null,
  dealer_is_axa: null,
  dealer_contract: {
    id: null,
    translations: [],
  },
  address: {
    street: '',
    postal_code: '',
    city: '',
    country: '',
  },
  language: 'en',
  contract_start_date: null,
  has_sso: null,
  contract_version: null,
};

export const swibeco = {
  name: '',
  address: {
    street: '',
    postal_code: '',
    city: '',
    country: '',
  },
};
