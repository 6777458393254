import {
  companyTypes,
  OnboardingActions,
  OnboardingCompanyState,
} from '../types';

const setCompany = (
  companyInfo: OnboardingCompanyState
): OnboardingActions => ({
  type: companyTypes.SET_COMPANY,
  payload: companyInfo,
});

const contractStep = (contract: string): OnboardingActions => ({
  type: companyTypes.CONTRACT_STEP,
  payload: contract,
});

const launchDateStep = (date: string): OnboardingActions => ({
  type: companyTypes.LAUNCH_DATE_STEP,
  payload: date,
});

export const onboardingCompanyActions = {
  setCompany,
  contractStep,
  launchDateStep,
};
