/* istanbul ignore file */
import { API } from '@swibeco/shared';
import { CompanyThemeType } from '@swibeco/types';

type AnonymousOnboardingCompanyResponse = {
  allowed_domains: string[];
  dealer_is_axa: boolean;
  theme: CompanyThemeType;
};

export type AnonymousOnboardingUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
};
export const getCompanyPerToken = async (
  token: string
): Promise<AnonymousOnboardingCompanyResponse> => {
  const response = await API.get(`/company/anonymous/onboarding/${token}`);

  return response.data;
};

export const createUserPerCompanyToken = async (
  token: string,
  data: AnonymousOnboardingUserRequest
): Promise<any> => {
  const response = await API.post(`/company/anonymous/onboarding/${token}`, {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    language: data.language,
  });

  return response;
};
