/* istanbul ignore file */
import { API } from '@swibeco/shared';
import { OnboardingStore } from '../store/types';

export const getUserPerActivationCode = async (
  token: string
): Promise<OnboardingStore> => {
  const response = await API.get(`/users/onboarding?activationCode=${token}`);
  return response.data;
};

export const getUserPerId = async (id: number): Promise<OnboardingStore> => {
  const response = await API.get(`/users/onboarding?id=${id}`);
  return response.data;
};
