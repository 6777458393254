import React from 'react';
import Footer from '../Footer/Footer';
import * as Styles from './PageBubblesLayout.styles';

interface PageBubblesLayoutProps {
  children?: React.ReactNode;
  withFooter?: boolean;
  withHoverBubble?: boolean;
}
const PageBubblesLayout = ({
  children,
  withFooter,
  withHoverBubble,
}: PageBubblesLayoutProps) => (
  <>
    <Styles.Wrapper>
      <Styles.TopLeftBubble />
      <Styles.RightBubble />
      <Styles.BottomLeftBubble withHoverBubble={withHoverBubble} />
      {withHoverBubble && <Styles.BottomLeftBubbleHover />}
      <Styles.Content>{children}</Styles.Content>
    </Styles.Wrapper>
    {withFooter && <Footer showLogoTitle={false} whiteCopyright />}
  </>
);

export default PageBubblesLayout;
