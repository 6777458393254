import { Chevron, Legals, SubTitle, Text } from '@swibeco/ui';
import { ChevronDirection } from '@swibeco/ui/lib/components/iconography/Chevron';
import React, { useContext } from 'react';
import { Collapse } from 'reactstrap';
import { StepperContext } from './Stepper';
import * as Styles from './styles/Step.styles';

type StepProps = {
  number: number;
  title: React.ReactNode;
  icon?: string;
  alt?: string;
  mobilePrefix?: string | React.ReactNode;
  children: React.ReactNode;
};

const Step = ({
  number,
  title,
  icon,
  alt,
  children,
  mobilePrefix,
}: StepProps) => {
  const { currentStep, numberOfSteps, goTo } = useContext(StepperContext);
  return (
    <Styles.Wrapper
      className={`w-100 mt-3 ${currentStep >= number ? 'd-block' : 'd-none'}`}
      data-testid={`step-${number}-wrapper`}
    >
      <Styles.Header
        currentStep={currentStep > number}
        className="d-flex align-items-center justify-content-between px-md-3 px-1 py-3"
        data-testid={`step-${number}-goto`}
        onClick={() => goTo(number)}
      >
        <div className="d-flex align-items-md-center flex-column flex-md-row">
          <div className="d-flex flex-row align-items-center ">
            <Styles.StepIcon
              visible={currentStep > number}
              className="mr-md-3 ml-2 order-md-1 order-2"
            />
            <SubTitle
              className="d-none d-md-block order-md-2"
              color="primary.main"
            >
              <strong>{number}</strong>
            </SubTitle>
            <Legals
              color="default.strong"
              className="align-self-start order-md-3 order-1 m-md-0 my-2"
            >
              <strong className="d-md-none ml-3">
                {`${mobilePrefix} ${number}`}
              </strong>
              <strong className="mr-2">/{numberOfSteps}</strong>
            </Legals>
          </div>
          <div className="d-flex flex-row">
            {icon && (
              <>
                <Styles.VerticalLine className="d-none d-md-block" />
                <Styles.StepExplanationIcon
                  src={icon}
                  alt={alt}
                  className="mx-3"
                />
              </>
            )}
            <Text className="flex-grow-1" bold>
              {title}
            </Text>
          </div>
        </div>

        {currentStep !== number ? (
          <Chevron className="mx-md-0 mx-3" direction={ChevronDirection.Down} />
        ) : null}
      </Styles.Header>
      <Collapse isOpen={currentStep === number} key={number}>
        <div className="px-4 py-3" data-testid={`step-${number}-content`}>
          {children}
        </div>
      </Collapse>
    </Styles.Wrapper>
  );
};

export default Step;
