import React, { useContext, useReducer } from 'react';
import { onboardingReducer } from '../store';
import { company, swibeco, user } from '../store/initialState';
import { OnboardingStore, OnboardingActions } from '../store/types';

export const OnboardingStoreContext = React.createContext<OnboardingStore>(
  {} as OnboardingStore
);

export const OnboardingDispatchContext = React.createContext<
  React.Dispatch<OnboardingActions>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>(/* istanbul ignore next */ () => {});

type OnboardingContextProps = {
  children: React.ReactNode;
};

export const useOnboardingContext = () => useContext(OnboardingStoreContext);
export const useOnboardingDispatch = () =>
  useContext(OnboardingDispatchContext);

const OnboardingContext = ({ children }: OnboardingContextProps) => {
  const [state, dispatch] = useReducer(onboardingReducer, {
    user,
    company,
    swibeco,
  });
  return (
    <OnboardingStoreContext.Provider value={state}>
      <OnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingDispatchContext.Provider>
    </OnboardingStoreContext.Provider>
  );
};

export default OnboardingContext;
