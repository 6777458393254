/* istanbul ignore file */
/**
 * Custom hook to add 7 days from the current one, weekend excluded
 * @param days Number of days to add to the current one
 * @returns {Date} Date from Monday to Friday
 */
const useWorkingDayWithDelaySelector = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);

  const day = date.getDay();

  // If day selected is Sunday, we had 1 to select Monday
  if (day === 0) return new Date(date.setDate(date.getDate() + 1));
  // If day selected is Saturday, we had 2 to select Monday
  else if (day === 6) return new Date(date.setDate(date.getDate() + 2));
  else return date;
};

export default useWorkingDayWithDelaySelector;
