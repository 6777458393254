import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '@swibeco/ui';
import { ErrorBoundary, ErrorPage } from '@swibeco/core-web';
import {
  onboardingActions,
  OnboardingContext,
  OnboardingDispatchContext,
  OnboardingStoreContext,
} from '@swibeco/onboarding';
import { ERROR, HOME, REGISTER, JOIN, JOIN_SUCCESS } from './path';

const SSOPage = lazy(() => import('../pages/SSOPage'));
const RegisterPage = lazy(() => import('../pages/RegisterPage'));
const AnonymousOnboardingPage = lazy(
  () => import('../pages/AnonymousOnboardingPage')
);
const AnonymousOnboardingSuccessPage = lazy(
  () => import('../pages/AnonymousOnboardingSuccessPage')
);

const Router = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language.substring(0, 2);
  const { user } = useContext(OnboardingStoreContext);
  const dispatch = useContext(OnboardingDispatchContext);

  useEffect(() => {
    if (user?.language !== locale) {
      dispatch(onboardingActions.user.changeLanguage(locale));
    }
  }, [locale, user?.language, dispatch]);

  return (
    <ErrorBoundary>
      <OnboardingContext>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={HOME} exact element={<SSOPage />} />
            <Route path={REGISTER} exact element={<RegisterPage />} />
            <Route
              path={JOIN_SUCCESS}
              exact
              element={AnonymousOnboardingSuccessPage}
            />
            <Route path={JOIN} exact element={<AnonymousOnboardingPage />} />
            <Route path={ERROR} element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </OnboardingContext>
    </ErrorBoundary>
  );
};

export default Router;
