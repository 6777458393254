import React, { useMemo, Suspense } from 'react';
import { Header } from '@swibeco/shared-web';
import { ThemeType } from '@swibeco/types';
import { BrowserRouter } from 'react-router-dom';
import { Router } from '@swibeco/onboarding-web';
import { Loader, SwibecoColors, customTheme, UIProvider } from '@swibeco/ui';
import { MainCSS, ThemeData, GlobalCSS } from './App.style';

const App = () => {
  const theme: ThemeType = {
    ...useMemo(() => ThemeData, []),
    ...useMemo(() => SwibecoColors, []),
  };

  return (
    <UIProvider theme={theme} customTheme={customTheme}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <div style={GlobalCSS}>
            <Header />
            <main style={MainCSS}>
              <Router />
            </main>
          </div>
        </BrowserRouter>
      </Suspense>
    </UIProvider>
  );
};

export default App;
