export type OnboardingUserState = {
  id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  is_hr_admin?: boolean;
  password: string | null;
  gender: string | null;
  language: string;
  picture: string | null;
  recovery_email: string | null;
  newsletter: boolean;
  hr_newsletter?: boolean;
  terms_accepted: boolean;
  axa_terms_accepted?: boolean;
  activation_code: string | null;
};

export type OnboardingCompanyState = {
  id: number | null;
  name: string;
  onboarding_complete: boolean;
  dealer_id: number | null;
  dealer_is_axa: boolean | null;
  dealer_contract: {
    id: number | null;
    translations: {
      content: string;
      locale: string;
    }[];
  };
  address: {
    street: string;
    postal_code: string;
    city: string;
    country: string;
  };
  language: string;
  contract_start_date: string | null;
  has_sso: boolean | null;
  contract_version: string | null;
};

export type OnboardingSwibecoState = {
  name: string;
  address: {
    street: string;
    postal_code: string;
    city: string;
    country: string;
  };
};

export type OnboardingStore = {
  user: OnboardingUserState;
  company: OnboardingCompanyState;
  swibeco: OnboardingSwibecoState;
};

export enum userTypes {
  SET_USER = 'SET_USER',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  PERSONAL_INFO_STEP = 'PERSONAL_INFO_STEP',
  PASSWORD_STEP = 'PASSWORD_STEP',
}

export enum companyTypes {
  SET_COMPANY = 'SET_COMPANY',
  CONTRACT_STEP = 'CONTRACT_STEP',
  LAUNCH_DATE_STEP = 'LAUNCH_DATE_STEP',
}

export enum swibecoTypes {
  SET_SWIBECO = 'SET_SWIBECO',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type Payload = {
  [userTypes.SET_USER]: OnboardingUserState;
  [userTypes.CHANGE_LANGUAGE]: string;
  [userTypes.PERSONAL_INFO_STEP]: {
    gender: string;
    recoveryEmail: string;
    newsletter: boolean;
    hr_newsletter?: boolean;
    termsUse: boolean;
    picture: string | undefined;
    axaTermsAccepted: boolean;
  };
  [userTypes.PASSWORD_STEP]: string;
  [companyTypes.SET_COMPANY]: OnboardingCompanyState;
  [companyTypes.CONTRACT_STEP]: string;
  [companyTypes.LAUNCH_DATE_STEP]: string;
  [swibecoTypes.SET_SWIBECO]: OnboardingSwibecoState;
};

export type OnboardingActions = ActionMap<Payload>[keyof ActionMap<Payload>];
