import { CSSProperties } from 'react';

export const ThemeData = {
  properties: {
    borderRadius: '5px',
  },
};

export const GlobalCSS: CSSProperties = {
  minHeight: '100vh',
  minWidth: '100%',
  boxSizing: 'border-box',
};

export const MainCSS: CSSProperties = {
  minWidth: '100%',
  minHeight: '90vh',
};
