/* istanbul ignore file */
import { OnboardingStore } from '../store/types';

export type TranslationResult =
  | {
      content: string;
      locale: string;
    }[]
  | [];

const getDealerContractTranslations = (
  data: OnboardingStore
): TranslationResult =>
  data.company.dealer_contract.translations.map(
    (t: { content: string; locale: string }) => {
      const content = t.content.replace(
        /SWIBECO_NAME|SWIBECO_ADDRESS_STREET|SWIBECO_ADDRESS_POSTAL_CODE|SWIBECO_ADDRESS_CITY|COMPANY_NAME|COMPANY_ADDRESS_STREET|COMPANY_ADDRESS_POSTAL_CODE|COMPANY_ADDRESS_CITY/gi,
        (x: string) => {
          if (!x) {
            return '';
          }
          return String(
            {
              SWIBECO_NAME: data.swibeco.name,
              SWIBECO_ADDRESS_STREET: data.swibeco.address.street,
              SWIBECO_ADDRESS_POSTAL_CODE: data.swibeco.address.postal_code,
              SWIBECO_ADDRESS_CITY: data.swibeco.address.city,
              COMPANY_NAME: data.company.name,
              COMPANY_ADDRESS_STREET: data.company.address.street,
              COMPANY_ADDRESS_POSTAL_CODE: data.company.address.postal_code,
              COMPANY_ADDRESS_CITY: data.company.address.city,
            }[x]
          );
        }
      );
      return {
        content,
        locale: t.locale,
      };
    }
  );

export default getDealerContractTranslations;
