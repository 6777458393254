import {
  OnboardingActions,
  swibecoTypes,
  OnboardingSwibecoState,
} from '../types';

const setSwibeco = (
  swibecoInfo: OnboardingSwibecoState
): OnboardingActions => ({
  type: swibecoTypes.SET_SWIBECO,
  payload: swibecoInfo,
});

export const onboardingSwibecoActions = {
  setSwibeco,
};
