/* istanbul ignore file */
import { API } from '@swibeco/shared';
import { OnboardingCompanyState, OnboardingUserState } from '../store/types';

export const updateUser = async (
  user: OnboardingUserState,
  company: OnboardingCompanyState
) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  // Redux non-serializable values in state or actions: we convert the createObjectURL string into a File once again
  let pictureFile: File | Blob | null = null;
  if (user.picture) {
    pictureFile = await fetch(user.picture).then((r) => r.blob());
    // On Google Chrome, a Blob instance is returned which cause an error
    if (pictureFile instanceof Blob) {
      pictureFile = new File(
        [pictureFile],
        `avatar.${pictureFile.type.split('/')[1]}`
      );
    }
  }

  const formData = new FormData();
  formData.append('activation_code', user.activation_code ?? '');
  // We send or the activation_code or the id, this way we know if the user is SSO (already connected)
  formData.append(
    'user_id',
    user.id && !user.activation_code ? JSON.stringify(user.id) : ''
  );
  formData.append('gender', user.gender!);
  formData.append('recovery_email', user.recovery_email ?? '');
  formData.append('newsletter', JSON.stringify(user.newsletter));
  formData.append('hr_newsletter', JSON.stringify(user.hr_newsletter));
  formData.append('terms_accepted', JSON.stringify(user.terms_accepted));
  formData.append(
    'axa_terms_accepted',
    JSON.stringify(user.axa_terms_accepted)
  );
  formData.append('plain_password', user.password ?? '');
  formData.append('language', user.language);
  formData.append('picture_file', pictureFile ?? '');
  formData.append('contract_start_date', company.contract_start_date || '');
  formData.append('contract', company.contract_version ?? '');

  return API.post('/users/complete-onboarding', formData, config);
};
