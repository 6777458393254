import { produce } from 'immer';
import { onboardingUserActions } from './actions/userActions';
import { onboardingCompanyActions } from './actions/companyActions';
import { onboardingSwibecoActions } from './actions/swibecoActions';
import {
  companyTypes,
  OnboardingStore,
  swibecoTypes,
  userTypes,
} from './types';

export const onboardingReducer = produce((state: OnboardingStore, action) => {
  switch (action.type) {
    case userTypes.SET_USER:
      state.user.id = action.payload.id;
      state.user.first_name = action.payload.first_name;
      state.user.last_name = action.payload.last_name;
      state.user.email = action.payload.email;
      state.user.language = action.payload.language;
      state.user.activation_code = action.payload.activation_code || null;
      state.user.is_hr_admin = action.payload.is_hr_admin || false;
      break;
    case userTypes.CHANGE_LANGUAGE:
      state.user.language = action.payload;
      break;
    case userTypes.PERSONAL_INFO_STEP:
      state.user.gender = action.payload.gender;
      state.user.terms_accepted =
        action.payload.termsUse || action.payload.axaTermsAccepted;
      state.user.newsletter = action.payload.newsletter;
      state.user.hr_newsletter = action.payload.hr_newsletter;
      state.user.recovery_email = action.payload.recoveryEmail || null;
      state.user.picture = action.payload.picture || null;
      state.user.axa_terms_accepted = action.payload.axaTermsAccepted || false;
      break;
    case userTypes.PASSWORD_STEP:
      state.user.password = action.payload;
      break;
    case companyTypes.SET_COMPANY:
      state.company.id = action.payload.id;
      state.company.name = action.payload.name;
      state.company.onboarding_complete = action.payload.onboarding_complete;
      state.company.dealer_id = action.payload.dealer_id;
      state.company.dealer_is_axa = action.payload.dealer_is_axa;
      state.company.dealer_contract = action.payload.dealer_contract;
      state.company.address.street = action.payload.address.street;
      state.company.address.postal_code = action.payload.address.postal_code;
      state.company.address.city = action.payload.address.city;
      state.company.address.country = action.payload.address.country;
      state.company.language = action.payload.language;
      state.company.has_sso = action.payload.has_sso;
      break;
    case companyTypes.CONTRACT_STEP:
      state.company.contract_version = action.payload;
      break;
    case companyTypes.LAUNCH_DATE_STEP:
      state.company.contract_start_date = action.payload;
      break;
    case swibecoTypes.SET_SWIBECO:
      state.swibeco.name = action.payload.name;
      state.swibeco.address.street = action.payload.address.street;
      state.swibeco.address.postal_code = action.payload.address.postal_code;
      state.swibeco.address.city = action.payload.address.city;
      state.swibeco.address.country = action.payload.address.country;
      break;
    default:
      break;
  }
});

export const onboardingActions = {
  user: onboardingUserActions,
  company: onboardingCompanyActions,
  swibeco: onboardingSwibecoActions,
};
